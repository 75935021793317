import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Deload Week`}</em></p>
    <p>{`Mobility: Squat/Single Leg Squat/Lunge/Hamstring specific`}</p>
    <p>{`25:00 EMOM:`}</p>
    <p>{`Minutes 1:00, 6:00, 11:00, 16:00, 21:00-10 Alternating Step Ups per leg
(24/20″)`}</p>
    <p>{`Minutes 2:00, 7:00, 12:00, 17:00, 22:00-20 Wall Balls (20/14)`}</p>
    <p>{`Minutes 3:00, 8:00, 13:00, 18:00, 23:00-20 Bent Leg Bridge on Ball`}</p>
    <p>{`Minutes 4:00, 9:00, 14:00, 19:00, 24:00-30 Full Crunch with Ball`}</p>
    <p>{`Minutes 5:00, 10:00, 15:00, 20:00, 25:00- :45 Plank`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      